<template>
<div>
    <div   style="padding-top:80px">
        <a-layout>
            <a-layout-content>
                <a-row style="padding:60px">
                    <a-col :span="24">
                        <div class="dash-t">
                            <a-row>
                                <a-col :span="8">
                                    <div class="dash-font1">스토어링크 이용방법</div>
                                    <div class="dash-font2">스토어링크 이용에 어려움이 있으시다면, 우측의 사용방법을 참고 해 보세요 😃</div>
                                </a-col>
                                <a-col :span="4">
                                    <div style="margin-top:-14px">
                                        <img style="width:154px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-oopserror%402x.png?alt=media&token=6d00b8bf-a6b3-4979-b354-b5fff3d9b095" />
                                    </div>
                                </a-col>
                                <a-col :span="12">
                                    <div style="float:right;display:flex;margin-top:40px">
                                        <div class="dash-btn1" style="margin-right:10px">
                                            <a style="color:#0264fb"  target="_blank" href="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2F21.02.18_.pdf?alt=media&token=459dd578-67f8-4f33-980b-c6601944856e">
                                            스토어링크 사용방법
                                            </a>
                                        </div>
                                        <div class="dash-btn1" style="margin-right:40px">
                                            <a style="color:#0264fb" target="_blank" href="https://storelink.io/doc/Naver-HowToUse.pdf">네이버 키워드 사용방법</a>
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                    </a-col>
                    <a-col :span="24" style="margin-top:60px">
                        <a-row>
                            <a-col :span="12">
                                <div style="text-align:left" class="dash-font3">내 캠페인</div>
                            </a-col>
                            <a-col :span="12">
                                <div style="text-align:left" class="dash-font3">총 진행비용</div>
                            </a-col>

                        </a-row>
                        <a-row>
                            <a-col :span="3" style="padding-right:10px">
                                <div class="dash-t2" v-if="loaded">
                                    <div class="num">
                                        {{progress}}
                                    </div>
                                    <div class="wait" style="margin-top:10px">
                                        진행중 캠페인
                                    </div>
                                </div>
                                <div class="dash-t2" style="text-align: center;padding-top: 80px;" v-else>
                                    <a-spin />
                                </div>
                            </a-col>
                            <a-col :span="3" style="padding-right:10px">
                                <div class="dash-t2" v-if="loaded">
                                    <div class="num">
                                        {{wait}}
                                    </div>
                                    <div class="wait" style="margin-top:10px">
                                        대기중 캠페인
                                    </div>
                                </div>
                                <div class="dash-t2" style="text-align: center;padding-top: 80px;" v-else>
                                    <a-spin />
                                </div>
                            </a-col>
                            <a-col :span="3" style="padding-right:10px">
                                <div class="dash-t2" v-if="loaded">
                                    <div class="num">
                                        {{apply}}
                                    </div>
                                    <div class="wait" style="margin-top:10px">
                                        신청한 캠페인
                                    </div>
                                </div>
                                <div class="dash-t2" style="text-align: center;padding-top: 80px;" v-else>
                                    <a-spin />
                                </div>
                            </a-col>
                            <a-col :span="3" style="padding-right:10px">
                                <div class="dash-t2" v-if="loaded">
                                    <div class="num">
                                        {{complete}}
                                    </div>
                                    <div class="wait" style="margin-top:10px">
                                        완료 캠페인
                                    </div>
                                </div>
                                <div class="dash-t2" style="text-align: center;padding-top: 80px;" v-else>
                                    <a-spin />
                                </div>
                            </a-col>
                            <a-col :span="6" style="padding-right:10px">
                                <div class="dash-t2" v-if="loaded">
                                    <div class="num">
                                        {{parseInt(total).toLocaleString()}}
                                    </div>
                                    <div class="wait" style="margin-top:10px">
                                        총 진행비용 합계
                                    </div>
                                </div>
                                <div class="dash-t2" style="text-align: center;padding-top: 80px;" v-else>
                                    <a-spin />
                                </div>
                            </a-col>
                            <a-col :span="6">
                                <div class="dash-t2" v-if="loaded">
                                    <div class="num">
                                        {{per.toLocaleString()}}
                                    </div>
                                    <div class="wait" style="margin-top:10px">
                                        캠페인당 평균 진행비용
                                    </div>
                                </div>
                                <div class="dash-t2" style="text-align: center;padding-top: 80px;" v-else>
                                    <a-spin />
                                </div>
                            </a-col>
                        </a-row>
                        <a-row style="margin-top:30px">
                            <a-col :span="24" style="text-align:center">
                                <div class="dash-t3"  @click="$router.push('/pages/target').catch(() => {})"><i class="fal fa-plus"></i>&nbsp;상위노출 캠페인 만들기</div>
                            </a-col>
                        </a-row>

                    </a-col>
                </a-row>
            </a-layout-content>
        </a-layout>
    </div>
    <nav-footer></nav-footer>
</div>
</template>

<script>
import SubMenu from "../../layouts/nav/SubMenu";
import NavFooter from "../../layouts/nav/NavFooter";
import { showError, showSuccess, isMobile } from "../components/fnc.js";
import firebase from "firebase";
import auth from "@/auth/authService";
export default {
  data() {
    return {
      current: "",
      progress: 0,
      wait: 0,
      apply: 0,
      complete: 0,
      total: 0,
      per: 0,
      all: 0,
      loaded: false
    };
  },
  computed: {
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  },
  components: {
    SubMenu,
    NavFooter
  },
  mounted() {
    if (this._mobile) {
      this.$router.push("/pages/notsupport").catch(() => {});
    } else {
      if (this.inValidCheckLogin()) {
        this.onLogOut();
      } else {
        this.loaded = false;
        this.onLoadCampaign();
      }
    }
  },
  methods: {
    onLogOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          auth.logOut();
          location.reload();
        });
    },
    inValidCheckLogin() {
      const firebaseCurrentUser = firebase.auth().currentUser;
      return (
        !this.$store.state.AppActiveUser.displayName && firebaseCurrentUser
      );
    },
    onWait() {
      showError({
        notify: this.$vs.notify,
        msg: "아직 준비중입니다."
      });
    },
    onLoadCampaign() {
      var self = this;
      self.applyData = [];
      var sref = firebase.database().ref("rank_apply/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var _status = sdatas.val().status;
            var _tp2 = parseInt(sdatas.val().tp2);

            if (_status == "C") {
              self.wait++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "E") {
              self.apply++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "A") {
              self.apply++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "F") {
              self.wait++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "W") {
              self.wait++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "P") {
              self.progress++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "X") {
              self.complete++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "B") {
              self.complete++;
              self.total += _tp2;
              self.all++;
            } else if (_status == "T") {
              self.complete++;
              self.total += _tp2;
              self.all++;
            }

            if (self.total > 0) {
              self.per = parseInt(self.total / self.all);
            }
          });
          self.loaded = true;
        });
    }
  }
};
</script>

<style>
.dash-t {
  width: 100%;
  height: 140px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.dash-t2 {
  width: 100%;
  height: 190px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin-top: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
}

.dash-t2:hover {
  background-color: #0264fb;
}

.dash-t2 .num {
  font-family: "Muli";
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2.63px;
  color: #000000;
}

.dash-t2 .wait {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.dash-t2:hover .num {
  color: #ffffff;
}

.dash-t2:hover .wait {
  color: #f8f8f8;
}

.dash-t3 {
  width: 200px;
  height: 60px;
  border-radius: 5px;
  border: solid 1px #0264fb;
  background-color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #0264fb;
  padding-top: 18px;
}

.dash-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
  margin-top: 42px;
  margin-left: 40px;
}

.dash-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
  margin-left: 40px;
}

.dash-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.dash-btn1 {
  width: 200px;
  height: 60px;
  border-radius: 12px;
  background-color: #f8f8f8;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #036ffc;
  padding-top: 18px;
  cursor: pointer;
}
</style>
